import React, { Component } from 'react'
import { Link } from 'gatsby'
import { theme, fontBold } from '../util/style'
import { imgUrl } from '../util/helpers'
import { Flex, Box } from '@rebass/grid'
import styled from 'styled-components'
import Image from '../components/page-image-new'
import Image200 from '../components/page-image-200'

import SlideVideo from './page-hero-gallery-video'
import IconLeftArrow from './svg/icon-left-arrow'
import IconRightArrow from './svg/icon-right-arrow'

class PageHeroGallery extends Component {
  constructor(props) {
    super(props)

    this.state = {
      percent: 0,
      isSmooth: false,
      currIndex: 0,
      defer: true,
    }

    this.timer = null
    this.timerHack = null
    this.deferTimer = null
    this.totalTimeMs = null
    this.timePerSlide = 8000
  }

  componentWillMount() {
    const { slides } = this.props
    this.totalTimeMs = this.timePerSlide * slides.length
  }

  componentDidMount() {
    this._startTimer(0)
    const self = this
    this.deferTimer = setTimeout(() => {
      self.setState({
        defer: false,
      })
    }, 2000)
  }

  componentWillUnmount() {
    clearTimeout(this.timer)
    clearTimeout(this.timerHack)
    clearTimeout(this.timerReset)
    clearInterval(this.frameChecker)
  }

  _getThumbContent() {
    const { ratio } = this.props
    const self = this

    let size = 'large'
    if (ratio && ratio === 1) {
      size = 'small'
    }

    //const slides = this.state.defer ? [this.props.slides[0]] : this.props.slides
    const slides = this.props.slides

    return slides.map(function (slide, i) {
      const key = `thumb-${i}`
      return (
        <Thumb
          key={key}
          onClick={(e) => {
            self._handleThumbClick(e, i)
          }}
          size={size}
        >
          <Image200 src={slide.image} alt="thumb" />
        </Thumb>
      )
    })
  }

  _startTimer(startSlide) {
    const self = this
    const { slides } = this.props

    const percent = (startSlide / slides.length) * 100

    clearTimeout(this.timer)
    clearTimeout(this.timerHack)
    clearTimeout(this.timerReset)
    clearInterval(this.frameChecker)

    this.totalTimeMs = this.timePerSlide * (slides.length - startSlide)

    this.timer = setTimeout(() => {
      self.setState({
        percent: 100,
        isSmooth: true,
      })
    }, 200)

    this.timerHack = setTimeout(() => {
      self.setState({
        percent: percent,
        isSmooth: false,
      })
    }, 100)

    const visualBuffer = 300

    this.timerReset = setTimeout(() => {
      self._startTimer(0)
    }, this.totalTimeMs + visualBuffer)

    let currMs = this.timePerSlide * slides.length - this.totalTimeMs
    const frameCheckSpeed = 200

    this.frameChecker = setInterval(() => {
      const { currIndex } = self.state
      const newSlideIndex = Math.floor(
        (currMs / (this.timePerSlide * slides.length)) * slides.length
      )
      if (currIndex !== newSlideIndex && newSlideIndex < slides.length) {
        self.setState({
          currIndex: newSlideIndex,
        })
      }
      currMs = currMs + frameCheckSpeed
    }, frameCheckSpeed)
  }

  _getSlideContent() {
    const { ratio } = this.props
    const { currIndex } = this.state

    const slides = this.state.defer ? [this.props.slides[0]] : this.props.slides

    return slides.map(function (slide, i) {
      const key = `thumb-${i}`
      const { tileTitle, buttons } = slide
      const isActive = currIndex === i

      let tileContent, buttonsContent, buttonContent
      if (tileTitle) {
        tileContent = (
          <Tile>
            <h3>{tileTitle}</h3>
          </Tile>
        )
      }

      if (buttons) {
        buttonsContent = buttons.map(function (button, y) {
          const bKey = `button-${y}`
          const { label, url, background, backgroundHover } = button
          return (
            <Cta
              buttonBg={background}
              key={bKey}
              buttonBgHover={backgroundHover}
            >
              <Link to={url}>{label}</Link>
            </Cta>
          )
        })
        buttonContent = <Ctas>{buttonsContent}</Ctas>
      }

      if (slide.format === 'video') {
        return (
          <Link to={slide?.link || '/'}>
            <Slide key={key} isActive={isActive} ratio={ratio}>
              <SlideVideo
                src={slide.video}
                poster={slide.image}
                index={i}
                currIndex={currIndex}
              />
              {tileContent}
              {buttonContent}
            </Slide>
          </Link>
        )
      } else {
        return (
          <Slide key={key} isActive={isActive} ratio={ratio}>
            <Image src={slide.image} alt="image" />
            {tileContent}
            {buttonContent}
          </Slide>
        )
      }
    })
  }

  _handleThumbClick(e, i) {
    this._startTimer(i)
  }

  render() {
    const { percent, isSmooth, defer } = this.state
    const { ratio, slides, hasCopy } = this.props
    const slideContent = this._getSlideContent()

    const animateWidth = `${percent}%`
    const totalTimeSecs = isSmooth ? this.totalTimeMs / 1000 : 0
    const animateTransition = `width ${totalTimeSecs}s linear`

    const handlePrevNex = (o = 1) => {
      const c = this.state.currIndex
      const s = slides
      let nc = parseInt(c + 1 * o)
      if (nc >= s.length) nc = 0
      if (nc < 0) nc = s.length - 1
      this._handleThumbClick(null, nc)
    }

    let thumbContent
    if (slides.length > 1) {
      thumbContent = (
        <Thumbs>
          <Indicator
            visible={true}
            animateWidth={animateWidth}
            animateTransition={animateTransition}
          />
          {this._getThumbContent()}
        </Thumbs>
      )
    }

    return (
      <Wrap>
        <Flex>
          <Box width={1} px={2}>
            <Gallery>
              <Slides ratio={ratio} hasCopy={hasCopy}>
                {slideContent}
              </Slides>
              {/* <ControlsWrapper>
                <Control mr={[1, 2]} onClick={() => handlePrevNex(-1)}>
                  <IconLeftArrow />
                </Control>
                <Control onClick={() => handlePrevNex(1)}>
                  <IconRightArrow />
                </Control>
              </ControlsWrapper> */}
              {/* {thumbContent} */}
            </Gallery>
          </Box>
        </Flex>
      </Wrap>
    )
  }
}

export default PageHeroGallery

const Wrap = styled.div`
  margin-bottom: 40px;
  @media (max-width: 800px) {
    margin-bottom: 32px;
  }

  .gatsby-image-wrapper {
    height: 100%;
  }
`

const Gallery = styled.div`
  position: relative;
`

//padding-top: ${(428/321)*100}%;

const Slides = styled.div`
  background: #eee;
  width: 100%;

  position: relative;
  padding-top: ${(props) =>
    props.ratio ? props.ratio * 100 : (9 / 16) * 100}%;
  overflow: hidden;
  height: 0;
  @media (max-width: 800px) {
    /* padding-top: ${(props) =>
      props.hasCopy === true
        ? `${(438 / 421) * 100}%`
        : props.ratio
        ? `${props.ratio * 100}%`
        : `${(9 / 16) * 100}%`}; */
    padding-top: ${(props) =>
      props.ratio ? props.ratio * 100 : (9 / 16) * 100}%;
  }
`

const Slide = styled.div`
  background: #fefefe;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: ${(props) => (props.isActive ? 1 : 0)};
  z-index: ${(props) => (props.isActive ? 2 : 1)};
  transition: opacity 0.2s linear;

  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: auto;
  }
  @media (max-width: 800px) {
    height: 100%;
  }
`

const Thumbs = styled.div`
  position: absolute;
  left: 0;
  bottom: -40px;
  @media (max-width: 1500px) {
    bottom: -20px;
  }
  @media (max-width: 800) {
    bottom: -20px;
  }
  z-index: 20;
  display: flex;
  flex-direction: row;
  cursor: pointer;
`

const Thumb = styled.div`
  width: ${(props) => (props.size === 'large' ? '170px' : '100px')};
  @media (max-width: 1500px) {
    width: ${(props) => (props.size === 'large' ? '120px' : '80px')};
  }
  @media (max-width: 800px) {
    width: ${(props) => (props.size === 'large' ? '70px' : '50px')};
  }
  img {
    max-width: 100%;
    width: auto;
    height: auto;
    display: block;
  }
`

const Indicator = styled.div`
  position: absolute;
  z-index: 10;
  left: 0;
  top: 0;
  height: 4px;
  width: 100%;
  opacity: ${({ visible }) => (visible ? `1` : `0`)};
  background: ${theme.colors.grey2};
  &:after {
    content: ' ';
    position: absolute;
    left: 0;
    top: 0;
    height: 4px;
    transition: ${(props) => props.animateTransition};
    width: ${(props) => props.animateWidth};
    background: ${theme.colors.grey1};
  }
`

const Tile = styled.div`
  background: rgba(241, 241, 241, 0.95);
  position: absolute;
  padding: 24px;
  right: 30px;
  top: 30px;
  @media (max-width: 800px) {
    padding: 16px;
    left: 0px;
    top: 0px;
    width: 100%;
    background: transparent;
    padding-top: 72%;
  }
  h3 {
    font-size: 100%;
    margin: 0;
    font-weight: normal;
  }
  p {
    margin: 0;
  }
`
const Ctas = styled.div`
  ${fontBold()}
  position: absolute;
  right: 15px;
  bottom: 15px;
  display: flex;
  flex-direction: row;
  @media (max-width: 800px) {
    right: 8px;
    bottom: 8px;
  }
`

const Cta = styled.div`
  a {
    color: ${theme.colors.white};
    background: ${(props) =>
      props.buttonBg ? props.buttonBg : theme.colors.grey1};
    &:hover {
      background: ${(props) =>
        props.buttonBgHover ? props.buttonBgHover : theme.colors.buttonHover};
    }
    display: block;
    padding: 20px 28px;
    margin-left: 20px;
    @media (max-width: 800px) {
      margin-left: 16px;
    }
  }
`

const ControlsWrapper = styled(Box)`
  position: absolute;
  display: flex;
  bottom: 0;
  right: 0;
  z-index: 10000;
  padding: 16px;
`

const Control = styled(Box)`
  width: 45px;
  height: 45px;
  /* @media (max-width: 800px) {
    width: 40px;
    height: 40px;
  } */
  color: white;
  svg {
    width: 100%;
    height: 100%;
    display: block;
  }

  cursor: pointer;
`
