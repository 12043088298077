import React, { Component } from 'react'
import Player from 'react-background-video-player'
import styled from 'styled-components'

export default class Hero extends Component {
  constructor(props) {
    super(props)

    let width, height
    if (typeof window !== `undefined`) {
      width = window.innerWidth
      height = window.innerHeight
    } else {
      width = 1440
      height = 900
    }

    this.state = {
      isPlaying: undefined,
      isMuted: undefined,
      progress: 0,
      currentTime: 0,
      duration: 0,
      windowWidth: width,
      windowHeight: height,
    }
  }

  componentDidMount() {
    this.setState({
      isPlaying: !this.player.isPaused,
      isMuted: this.player.isMuted,
    })
    if (this.props.index === this.props.currIndex) {
      this.handleOnPlay()
    }
    this.handleResize()
    if (typeof window !== `undefined`) {
      window.addEventListener('resize', this.handleResize)
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.currIndex !== this.props.currIndex &&
      nextProps.currIndex === this.props.index
    ) {
      this.handleOnPlay()
    } else {
      this.handleOnPause()
    }
  }

  componentWillUnmount() {
    if (typeof window !== `undefined`) {
      window.removeEventListener('resize', this.handleResize)
    }
  }

  componentWillMount() {
    if (typeof window !== `undefined`) {
      window.removeEventListener('resize', this.handleResize)
    }
  }

  handleResize = () => {
    if (typeof window !== `undefined`) {
      this.setState({
        windowWidth: window.innerWidth,
        windowHeight: window.innerHeight,
      })
    }
  }

  handleOnPlay = () => {
    this.setState({ isPlaying: true })
    this.player.setCurrentTime(0)
  }

  handleOnPause = () => {
    this.setState({ isPlaying: false })
  }

  handleTimeUpdate = (currentTime, progress, duration) => {
    this.setState({
      progress,
      currentTime,
      duration,
    })
  }

  handleOnMute = () => {
    this.setState({ isMuted: true })
  }

  handleOnUnmute = () => {
    this.setState({ isMuted: false })
  }

  togglePlay = () => {
    this.player.togglePlay()
  }

  toggleMute = () => {
    this.player.toggleMute()
  }

  render() {
    const { src, poster } = this.props

    return (
      <Wrap>
        <Player
          ref={(p) => (this.player = p)}
          containerWidth={this.state.windowWidth}
          containerHeight={this.state.windowHeight}
          src={src}
          poster={poster}
          onPlay={this.handleOnPlay}
          onPause={this.handleOnPause}
          onMute={this.handleOnMute}
          onUnmute={this.handleOnUnmute}
          onTimeUpdate={this.handleTimeUpdate}
          startTime={0}
          autoPlay={true}
          volume={0}
        />
      </Wrap>
    )
  }
}

const Wrap = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  /* @media (max-width: 800px) {
    height: 54.4%;
  } */
  video {
    height: 100%;
    width: 100%;
    top: 0 !important;
    left: 0 !important;
  }
`
